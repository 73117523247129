import React from 'react';
import PropTypes from 'prop-types';

const Password = ({ password, handlePasswordChange }) => {
    Password.propTypes = {
        password: PropTypes.string,
        handlePasswordChange: PropTypes.func,
    };

    return (
        <div className="section">
            <h1 className="h1-primary">Entsperren</h1>
            <div className="flex flex-col justify-center items-center">
                <p className="text-center text-xl sm:text-2xl pb-10 sm:px-[10%] px-5 text-stone-600">Bitte Passwort eingeben, um vollständige Seite anzuzeigen.</p>
                <input
                    type="text"
                    name="pass"
                    id="pass"
                    placeholder="Passwort"
                    value={password}
                    className="form-input w-52 justify-center items-center text-center border-solid border-2 border-mfrust p-2 focus:border-stone-600 active:border-stone-600"
                    onChange={handlePasswordChange}
                ></input>
            </div>
        </div>
    );
};

export default Password;
