import React, { useState } from 'react';
import Hero from './components/Hero';
import Countdown from './components/Countdown';
import Location from './components/Location';
import Schedule from './components/Schedule';
import Lodging from './components/Lodging';
import Gallery from './components/Gallery';
import Feedback from './components/Feedback';
import Password from './components/Password';
import Guests from './components/Guests';
import MenuBar from './components/MenuBar';
import Contact from './components/Contact';

const App = () => {
    const [password, setPassword] = useState('');

    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setPassword(value);
    };

    return (
        <>
            <MenuBar />
            <Hero />
            <Countdown />
            {/* Hidden behind password */}
            {password === 'Hochzeit2023' || password === 'OurBeautifulWedding' ? (
                <>
                    <Schedule />
                    <Location />
                    <Lodging />
                    <Feedback />
                    <Contact />
                    <Gallery />
                </>
            ) : (
                <Password password={password} handlePasswordChange={handlePasswordChange} />
            )}
            {password === 'OurBeautifulWedding' ? <Guests /> : null}
        </>
    );
};

export default App;
