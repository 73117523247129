import React from 'react';
import imgRing from '../assets/hochzeitsring.png';
import imgEmpfang from '../assets/champagner.png';
import imgFoto from '../assets/fotoapparat.png';
import imgEssen from '../assets/restaurant.png';
import imgParty from '../assets/girlanden.png';
import imgOrt from '../assets/hochzeitsort.png';

/* 873510
57534E
*/
const Schedule = () => {
    return (
        <div id="Ablauf" className="section">
            <h1 className="h1-primary">Ablauf</h1>
            <div className="flex flex-col md:grid grid-cols-12 mx-auto p-2 max-w-4xl">
                {/* LEFT */}
                <div className="flex flex-row-reverse md:contents timeline-left ">
                    <div className="col-span-5 py-4 my-4 md:ml-auto md:mr-0 mr-auto">
                        <div className="flex md:flex-row-reverse">
                            <div className="flex justify-center items-center w-20 h-20 shrink-0 md:ml-4">
                                <img src={imgOrt} alt="Ring" />
                            </div>
                            <div className="flex flex-col justify-center p-4">
                                <h3 className="font-semibold text-lg mb-1 text-mfrust">Ankunft der Gäste</h3>
                                <p className="leading-tight text-justify text-stone-600 md:text-right">ab 15:00</p>
                            </div>
                        </div>
                    </div>
                    {/* Line First Element */}
                    <div className="col-span-2 md:mx-auto relative mr-10">
                        <div className="h-full w-6 flex items-center justify-center">
                            <div className="h-[50%] mt-auto w-[2px] bg-stone-400 pointer-events-none"></div>
                        </div>
                        {/* Dot on Line */}
                        <div className="w-3 h-3 absolute top-1/2 ml-[6px] rounded-full bg-stone-400 shadow"></div>
                    </div>
                    <div className="col-span-5 w-5"></div>
                </div>
                {/* RIGHT */}
                <div className="flex md:contents timeline-right">
                    <div className="col-span-5 w-5"></div>
                    {/* Line Middle Element */}
                    <div className="col-span-2 mr-10 md:mx-auto relative">
                        <div className="h-full w-6 flex items-center justify-center">
                            <div className="h-full w-[2px] bg-stone-400 pointer-events-none"></div>
                        </div>
                        {/* Dot on Line */}
                        <div className="w-3 h-3 absolute top-1/2 ml-[6px] rounded-full bg-stone-400 shadow"></div>
                    </div>

                    <div className="col-span-5 py-4 my-4 mr-auto">
                        <div className="flex">
                            <div className="flex justify-center items-center w-20 h-20 shrink-0">
                                <img src={imgRing} alt="Ring" />
                            </div>
                            <div className="flex flex-col justify-center p-4">
                                <h3 className="font-semibold text-lg mb-1 text-mfrust">Freie Trauung</h3>
                                <p className="leading-tight text-justify text-stone-600">16:00</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* LEFT */}
                <div className="flex flex-row-reverse md:contents timeline-left">
                    <div className="col-span-5 py-4 my-4 md:ml-auto md:mr-0 mr-auto">
                        <div className="flex md:flex-row-reverse">
                            <div className="flex justify-center items-center w-20 h-20 shrink-0 md:ml-4">
                                <img src={imgEmpfang} alt="Ring" />
                            </div>
                            <div className="flex flex-col justify-center p-4">
                                <h3 className="font-semibold text-lg mb-1 text-mfrust">Sektempfang</h3>
                                <p className="leading-tight text-justify text-stone-600 md:text-right">17:00</p>
                            </div>
                        </div>
                    </div>
                    {/* Line Middle Element */}
                    <div className="col-span-2 mr-10 md:mx-auto relative">
                        <div className="h-full w-6 flex items-center justify-center">
                            <div className="h-full w-[2px] bg-stone-400 pointer-events-none"></div>
                        </div>
                        {/* Dot on Line */}
                        <div className="w-3 h-3 absolute top-1/2 ml-[6px] rounded-full bg-stone-400 shadow"></div>
                    </div>
                    <div className="col-span-5 w-5"></div>
                </div>
                {/* RIGHT */}
                <div className="flex md:contents timeline-right">
                    <div className="col-span-5 w-5"></div>
                    {/* Line Middle Element */}
                    <div className="col-span-2 mr-10 md:mx-auto relative">
                        <div className="h-full w-6 flex items-center justify-center">
                            <div className="h-full w-[2px] bg-stone-400 pointer-events-none"></div>
                        </div>
                        {/* Dot on Line */}
                        <div className="w-3 h-3 absolute top-1/2 ml-[6px] rounded-full bg-stone-400 shadow"></div>
                    </div>

                    <div className="col-span-5 py-4 my-4 mr-auto">
                        <div className="flex">
                            <div className="flex justify-center items-center w-20 h-20 shrink-0">
                                <img src={imgFoto} alt="Ring" />
                            </div>
                            <div className="flex flex-col justify-center p-4">
                                <h3 className="font-semibold text-lg mb-1 text-mfrust">Fotos</h3>
                                <p className="leading-tight text-justify text-stone-600">17:15</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* LEFT */}
                <div className="flex flex-row-reverse md:contents timeline-left">
                    <div className="col-span-5 py-4 my-4 md:ml-auto md:mr-0 mr-auto">
                        <div className="flex md:flex-row-reverse">
                            <div className="flex justify-center items-center w-20 h-20 shrink-0 md:ml-4">
                                <img src={imgEssen} alt="Ring" />
                            </div>
                            <div className="flex flex-col justify-center p-4">
                                <h3 className="font-semibold text-lg mb-1 text-mfrust">Essen</h3>
                                <p className="leading-tight text-justify text-stone-600 md:text-right">19:00</p>
                            </div>
                        </div>
                    </div>
                    {/* Line Middle Element */}
                    <div className="col-span-2 mr-10 md:mx-auto relative">
                        <div className="h-full w-6 flex items-center justify-center">
                            <div className="h-full w-[2px] bg-stone-400 pointer-events-none"></div>
                        </div>
                        {/* Dot on Line */}
                        <div className="w-3 h-3 absolute top-1/2 ml-[6px] rounded-full bg-stone-400 shadow"></div>
                    </div>
                    <div className="col-span-5 w-5"></div>
                </div>
                {/* RIGHT */}
                <div className="flex md:contents timeline-right">
                    <div className="col-span-5 w-5"></div>
                    {/* Line Last Element */}
                    <div className="col-span-2 mr-10 md:mx-auto relative">
                        <div className="h-full w-6 flex items-center justify-center">
                            <div className="h-[50%] mb-auto w-[2px] bg-stone-400 pointer-events-none"></div>
                        </div>
                        {/* Dot on Line */}
                        <div className="w-3 h-3 absolute top-1/2 ml-[6px] rounded-full bg-stone-400 shadow"></div>
                    </div>

                    <div className="col-span-5 py-4 my-4 mr-auto">
                        <div className="flex">
                            <div className="flex justify-center items-center w-20 h-20 shrink-0">
                                <img src={imgParty} alt="Ring" />
                            </div>
                            <div className="flex flex-col justify-center p-4">
                                <h3 className="font-semibold text-lg mb-1 text-mfrust">Party</h3>
                                <p className="leading-tight text-justify text-stone-600">21:00</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Schedule;
