import React from 'react';

import img2 from '../assets/2.jpg';
import img4 from '../assets/4.jpg';
import img3 from '../assets/3.jpg';
import img6 from '../assets/6.jpg';
import img7 from '../assets/7.jpg';
import img8 from '../assets/8.jpg';

const Gallery = () => {
    return (
        <div id="Galerie" className="section bg-mfrust px-5">
            {/* <h1 className="h1-primary text-white"></h1> */}
            <div className="flex flex-wrap justify-center gap-5">
                <img className="md:max-h-96 max-h-48" src={img2} alt="We 2" />
                <img className="md:max-h-96 max-h-48" src={img4} alt="We 4" />
                <img className="md:max-h-96 max-h-48" src={img6} alt="We 6" />
                <img className="md:max-h-96 max-h-48" src={img7} alt="We 7" />
                <img className="md:max-h-96 max-h-48" src={img8} alt="We 8" />
                <img className="md:max-h-96 max-h-48" src={img3} alt="We 3" />
            </div>
        </div>
    );
};

export default Gallery;
