import React from 'react';

// import imgWasserburg from '../assets/wasserburg.jpg';
// import imgHotel from '../assets/hotel.jpg';

const Lodging = () => {
    return (
        <div id="Lodging" className="section ">
            <h1 className="h1-primary">Unterbringung</h1>
            <div className="flex flex-col justify-center text-center">
                <p className="text-center text-xl sm:text-xl pb-10 sm:px-[10%] px-5 text-stone-600">
                    In der Umgebung gibt es verschiedene Unterkünfte. Wir haben euch hier eine Auswahl zusammengestellt. Bei Fragen kommt gerne auf uns zu.
                </p>

                <div className="flex flex-wrap justify-center">
                    {/* Wasserburg Hainchen */}
                    <div className="max-w-sm m-5">
                        <div className="p-5 text-start">
                            <a href="https://www.siegerlaenderburgenverein.de/index.php/ct-wasserburg-hainchen/ct-uebernachtungen" target="_blank" rel="noreferrer">
                                <h5 className="mb-4 text-xl tracking-tight text-mfrust hover:text-gray-700">Wasserburg Hainchen</h5>
                            </a>
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                    />
                                </svg>
                                <p className="mb-3 font-normal text-gray-700">Einzel-, Doppel- oder Mehrbettzimmer im Jugendherbergsstil</p>
                            </div>
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                                    />
                                </svg>

                                <p className="mb-3 font-normal text-gray-700">Direkt an der Location</p>
                            </div>
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                    />
                                </svg>

                                <p className="mb-3 font-normal text-gray-700">+49 271 84822</p>
                            </div>
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                    />
                                </svg>

                                <div>
                                    <p className="font-normal text-gray-700 mb-0">wasserburghainchen@web.de</p>
                                    <p className="text-sm text-gray-500">Zimmer bis zum 30.06.2023 reserviert.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Jokebes */}
                    <div className="max-w-sm m-5">
                        <div className="p-5 text-start">
                            <a href="https://www.gasthof-jokebes.de/" target="_blank" rel="noreferrer">
                                <h5 className="mb-4 text-xl tracking-tight text-mfrust hover:text-gray-700">Gasthof Jokebes</h5>
                            </a>
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                    />
                                </svg>
                                <p className="mb-3 font-normal text-gray-700">Einzel- und Doppelzimmer inkl. Frühstück</p>
                            </div>
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                                    />
                                </svg>

                                <p className="mb-3 font-normal text-gray-700">ca. 20 Minuten zu Fuß entfernt</p>
                            </div>
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                    />
                                </svg>

                                <p className="mb-3 font-normal text-gray-700">+49 2737 9583</p>
                            </div>
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                    />
                                </svg>

                                <div>
                                    <p className="font-normal text-gray-700 mb-0">info@gasthof-jokebes.de</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Ley */}
                    <div className="max-w-sm m-5">
                        <div className="p-5 text-start">
                            <a href="https://www.gasthof-ley.de/" target="_blank" rel="noreferrer">
                                <h5 className="mb-4 text-xl tracking-tight text-mfrust hover:text-gray-700">Gasthof Ley</h5>
                            </a>
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                    />
                                </svg>
                                <p className="mb-3 font-normal text-gray-700">Einzel- und Doppelzimmer inkl. Frühstück</p>
                            </div>
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                                    />
                                </svg>

                                <p className="mb-3 font-normal text-gray-700">ca. 20 Minuten zu Fuß entfernt</p>
                            </div>
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                    />
                                </svg>

                                <p className="mb-3 font-normal text-gray-700">+49 2737 91094</p>
                            </div>
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                    />
                                </svg>

                                <div>
                                    <p className="font-normal text-gray-700 mb-0">info@gasthof-ley.de</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Sonstige */}
                <div className="flex justify-center w-screen">
                    <div className="m-5">
                        <div className="p-5">
                            <h5 className="mb-4 text-xl tracking-tight text-mfrust">Weitere Übernachtungsmöglichkeiten</h5>
                            <div className="flex flex-col sm:flex-row sm:space-x-10">
                                <p>
                                    <a href="http://www.landgasthof-drei-eichen.de/" target="_blank" rel="noreferrer" className="text-gray-700 hover:text-mfrust">
                                        Landgasthof Drei Eichen
                                    </a>
                                </p>
                                <p>
                                    <a href="https://hotel-fuenf10.de/" target="_blank" rel="noreferrer" className="text-gray-700 hover:text-mfrust">
                                        Hotel Fünf10
                                    </a>
                                </p>
                                <p>
                                    <a href="https://www.ewerts.de/" target="_blank" rel="noreferrer" className="text-gray-700 hover:text-mfrust">
                                        Ewerts Hotel
                                    </a>
                                </p>
                                <p>
                                    <a href="https://pension-zum-rothaarsteig.de/" target="_blank" rel="noreferrer" className="text-gray-700 hover:text-mfrust">
                                        Pension zum Rothaarsteig
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Lodging;
