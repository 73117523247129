import React, { useEffect, useState } from 'react';
import Person from './helper/Person';

const Feedback = () => {
    const [guest, setFormData] = useState({ firstname: '', lastname: '', id: 'g1' });

    const [submitted, setSubmitted] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const [coming, setComing] = useState(false);
    const [notComing, setNotComing] = useState(false);
    const [adults, setAdults] = useState(0);
    const [adultList, setAdultList] = useState([]);
    const [kids, setKids] = useState(0);
    const [kidList, setKidList] = useState([]);

    const [adultListRender, setAdultListRender] = useState('<></>');
    const [kidListRender, setKidListRender] = useState('<></>');

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        setSuccess(false);
        setError(false);

        sendFormData();
    };

    const sendFormData = async () => {
        // Create json
        var data = { guest: guest, coming: coming, notComing: notComing, adults: adults, adultList: adultList, kids: kids, kidList: kidList, password: 'Wedding2023' };
        var jsonData = JSON.stringify(data);
        console.log(jsonData);
        try {
            let response = await fetch('http://18.196.104.49:9090/guests/create', {
                // let response = await fetch('http://3.127.81.122:9090/guests/create', {
                // let response = await fetch('http://localhost:9090/guests/create', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: jsonData,
            });
            if (!response.ok) {
                if (response.status === 403) {
                    setSubmitted(false);
                } else {
                    throw new Error('Data could not be posted!');
                }
            } else {
                var json = response.json();
                if (response.status === 201) {
                    setSuccess(true);
                    setSubmitted(false);
                }
                return json;
            }
        } catch (e) {
            setSubmitted(false);
            setSuccess(false);
            setError(true);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const field = name.split('-')[0];

        setFormData({
            ...guest,
            [field]: value,
        });
    };

    const handleComingChange = (e) => {
        const { checked } = e.target;
        if (checked) {
            // Uncheck not coming checkbox
            setNotComing(false);
        }
        setComing(checked);
    };

    const handleNotComingChange = (e) => {
        const { checked } = e.target;
        if (checked) {
            // Uncheck coming checkbox
            setComing(false);
            setAdults(0);
            setKids(0);
        }
        setNotComing(checked);
    };

    const handleAdultChange = (e) => {
        setAdults(e.target.value);
    };

    const handleAdultListChange = (e) => {
        const { name, value } = e.target;
        const field = name.split('-')[0];
        const id = name.split('-')[1];
        setAdultList((prev) => prev.map((adult) => (adult.id === id ? { ...adult, [field]: value } : adult)));
    };

    const handleKidChange = (e) => {
        setKids(e.target.value);
    };

    const handleKidListChange = (e) => {
        const { name, value } = e.target;
        const field = name.split('-')[0];
        const id = name.split('-')[1];
        setKidList((prev) => prev.map((kid) => (kid.id === id ? { ...kid, [field]: value } : kid)));
    };

    useEffect(() => {
        var adultList = [];
        for (var i = 0; i < adults; i++) adultList.push({ id: 'a' + i, firstname: '', lastname: '' });
        setAdultList(adultList);
    }, [adults]);

    useEffect(() => {
        setAdultListRender(
            adultList.map((adult) => {
                const { id, firstname, lastname } = adult;
                return <Person key={id} id={id} firstname={firstname} lastname={lastname} handleInputChange={handleAdultListChange} disabled={submitted || success} />;
            })
        );
    }, [adultList, submitted, success]);

    useEffect(() => {
        var kidList = [];
        for (var i = 0; i < kids; i++) kidList.push({ id: 'k' + i, firstname: '', lastname: '' });
        setKidList(kidList);
    }, [kids]);

    useEffect(() => {
        setKidListRender(
            kidList.map((kid) => {
                const { id, firstname, lastname } = kid;
                return <Person key={id} id={id} firstname={firstname} lastname={lastname} handleInputChange={handleKidListChange} disabled={submitted || success} />;
            })
        );
    }, [kidList, submitted, success]);

    return (
        <div id="Feedback" className="section flex flex-col justify-center items-center px-5 bg-mfmedium/10">
            <h1 className="h1-primary">Rückmeldung</h1>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col justify-center space-y-4 md:max-w-xl max-w-md">
                    <Person id="guest" firstname={guest.firstname} lastname={guest.lastname} handleInputChange={handleInputChange} disabled={submitted || success} />

                    <div className="flex space-x-4">
                        <input
                            id="coming"
                            name="coming"
                            type="checkbox"
                            value={coming}
                            checked={coming}
                            onChange={handleComingChange}
                            className="w-10 h-10 text-mfrust bg-white border-mfrust focus:border-0 focus:bg-mfrust/50 checked:bg-mfrust border-2 disabled:checked:bg-mfrust/50 disabled:border-mfrust/50"
                            disabled={submitted || success}
                        />
                        <label htmlFor="coming" className="ml-2 font-medium text-stone-600 flex items-center">
                            Ich komme zu eurer Hochzeit
                        </label>
                    </div>
                    <div className="flex space-x-4">
                        <input
                            id="notComing"
                            name="notComing"
                            type="checkbox"
                            value={notComing}
                            checked={notComing}
                            onChange={handleNotComingChange}
                            className="w-10 h-10 text-mfrust bg-white border-mfrust focus:border-0 focus:bg-mfrust/50 checked:bg-mfrust border-2 disabled:checked:bg-mfrust/50 disabled:border-mfrust/50"
                            disabled={submitted || success}
                        />
                        <label htmlFor="coming" className="ml-2 font-medium text-stone-600 flex items-center">
                            Ich kann leider nicht kommen
                        </label>
                    </div>

                    {!coming || notComing ? null : (
                        <>
                            <p className="text-mfrust font-medium">Begleitungen</p>
                            <div className="flex space-x-4 items-center ">
                                <div className="w-1/2 min-w-fit">
                                    <input
                                        type="number"
                                        name="adults"
                                        id="adults"
                                        placeholder="0"
                                        className="w-10 justify-center items-center text-center border-solid border-2 border-mfrust p-2 focus:border-stone-600 active:border-stone-600 disabled:border-mfrust/50"
                                        value={adults}
                                        onChange={handleAdultChange}
                                        disabled={submitted || success}
                                    />
                                    <label htmlFor="adults" className="w-1/2 ml-4 font-medium text-stone-600">
                                        {adults === '1' ? 'Erwachsener' : 'Erwachsene'}
                                    </label>
                                </div>
                                <div className="w-1/2 min-w-fit">
                                    <input
                                        type="text"
                                        name="kids"
                                        id="kids"
                                        placeholder="0"
                                        className="w-10 justify-center items-center text-center border-solid border-2 border-mfrust p-2 focus:border-stone-600 active:border-stone-600 disabled:border-mfrust/50"
                                        value={kids}
                                        onChange={handleKidChange}
                                        disabled={submitted || success}
                                    />
                                    <label htmlFor="adults" className="w-1/2 ml-4 font-medium text-stone-600">
                                        {kids === '1' ? 'Kind' : 'Kinder'}
                                    </label>
                                </div>
                            </div>
                        </>
                    )}

                    {adults > 0 ? (
                        <>
                            <p className="text-mfrust font-medium">{adults === '1' ? 'Erwachsener' : 'Erwachsene'}</p>
                            <div>{adultListRender}</div>
                        </>
                    ) : null}

                    {kids > 0 ? (
                        <>
                            <p className="text-mfrust font-medium">{kids === '1' ? 'Kind' : 'Kinder'}</p>
                            <div>{kidListRender}</div>
                        </>
                    ) : null}

                    {error ? (
                        <div>
                            <p className="text-mfrust text-center">Fehler bei der Anfrage</p>
                        </div>
                    ) : null}

                    {submitted ? (
                        <div className="flex justify-center">
                            <div className="w-8 h-8 border-4 border-stone-600 border-solid rounded-full animate-spin border-t-transparent"></div>
                        </div>
                    ) : null}

                    {success ? (
                        <div>
                            <p className="text-mfgreen text-center">Erfolgreich abgeschickt</p>
                        </div>
                    ) : null}

                    <div>
                        <button type="submit" className="w-full mt-4 bg-mfrust text-white p-2 disabled:bg-mfrust/50" disabled={submitted}>
                            Anmelden
                        </button>
                    </div>

                    <div className="flex flex-col border bg-white border-gray-200 shadow-md p-8 justify-center text-center">
                        <div className="text-gray-500">
                            Money, Money, Money - Geld ist ja nicht alles... aber wir können es trotzdem ganz gut gebrauchen! Wenn ihr uns beschenken wollt, macht ihr uns damit die größte Freude.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Feedback;
