import React from 'react';

const Contact = () => {
    return (
        <div id="Contact" className="section ">
            <h1 className="h1-primary pt-10">Kontakt</h1>
            <div className="flex flex-col sm:flex-row sm:justify-center sm:space-x-12 space-y-10 sm:space-y-0">
                <div className="text-center">
                    <p className="text-xl tracking-tight text-mfrust">Unsere Kontaktdaten</p>
                    <div className="text-gray-700">Franzi: +49 176 84280643</div>
                    <div className="text-gray-700">Marian: +49 151 72102567</div>
                </div>
                <div className="text-center">
                    <p className="text-xl tracking-tight text-mfrust">Unsere Trauzeugen</p>
                    <div className="text-gray-700">Hannah: +49 176 84280645</div>
                    <div className="text-gray-700">Daniel: +49 151 197000192</div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
