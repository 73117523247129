import React, { useState } from 'react';

const MenuBar = () => {
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

    const handleMobileMenu = (e) => {
        e.preventDefault();
        setMobileMenuOpened((prev) => !prev);
    };

    const closeMenu = () => {
        setMobileMenuOpened(false);
    };

    return (
        // <nav className="sticky top-0 z-50 bg-white p-3 flex justify-center align-middle shadow-lg">
        //     {/* Hamburger Menu */}
        //     <div className="sm:hidden">
        //         <button data-collapse-toggle="navbar-default" type="button" className="navbar-burger flex items-center text-gray-700">
        //             <svg className="block h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        //                 <title>Mobile menu</title>
        //                 <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        //             </svg>
        //         </button>
        //     </div>
        //     <div className="hidden w-full md:block md:w-auto" id="navbar-default">
        //         <ul className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:items-center lg:w-auto lg:space-x-6">
        //             <li>
        //                 <a className="text-sm text-gray-400 hover:text-gray-500" href="#">
        //                     Home
        //                 </a>
        //             </li>
        //         </ul>
        //     </div>
        //     {/* Normal Menu */}
        //     <div className="hidden sm:block space-x-4 text-gray-700">
        //         <a href="#Home">Home</a>
        //         <a href="#Ablauf">Ablauf</a>
        //         <a href="#Location">Location</a>
        //         <a href="#Lodging">Übernachtung</a>
        //         <a href="#Feedback">Rückmeldung</a>
        //         <a href="#Contact">Kontakt</a>
        //         <a href="#Galerie">Galerie</a>
        //     </div>
        // </nav>

        <nav className="sticky top-0 z-50 bg-white">
            {/* Desktop Menu */}
            <div className="hidden md:flex flex-wrap justify-center shadow-lg w-full p-4">
                <ul className="flex flex-row space-x-6">
                    <li>
                        <a href="#Home" className="hover:text-mfrust">
                            Home
                        </a>
                    </li>
                    <li>
                        <a href="#Ablauf" className="hover:text-mfrust">
                            Ablauf
                        </a>
                    </li>
                    <li>
                        <a href="#Location" className="hover:text-mfrust">
                            Location
                        </a>
                    </li>
                    <li>
                        <a href="#Lodging" className="hover:text-mfrust">
                            Übernachtung
                        </a>
                    </li>
                    <li>
                        <a href="#Feedback" className="hover:text-mfrust">
                            Rückmeldung
                        </a>
                    </li>
                    <li>
                        <a href="#Contact" className="hover:text-mfrust">
                            Kontakt
                        </a>
                    </li>
                    <li>
                        <a href="#Galerie" className="hover:text-mfrust">
                            Galerie
                        </a>
                    </li>
                </ul>
            </div>

            {/* Mobile Menu */}
            <div className="flex md:hidden flex-wrap justify-center shadow-lg w-full">
                <button
                    data-collapse-toggle="navbar-default"
                    type="button"
                    className="inline-flex items-center p-3 text-sm text-gray-700 rounded-lg md:hidden focus:outline-none"
                    aria-controls="navbar-default"
                    aria-expanded="false"
                    onClick={handleMobileMenu}
                >
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>

                <div className={mobileMenuOpened ? 'block w-full' : 'hidden w-full'} id="navbar-default">
                    <ul className="flex flex-col bg-white text-center space-y-2 mb-4">
                        <li>
                            <a href="#Home" onClick={() => closeMenu()} className="hover:text-mfrust">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="#Ablauf" onClick={() => closeMenu()} className="hover:text-mfrust">
                                Ablauf
                            </a>
                        </li>
                        <li>
                            <a href="#Location" onClick={() => closeMenu()} className="hover:text-mfrust">
                                Location
                            </a>
                        </li>
                        <li>
                            <a href="#Lodging" onClick={() => closeMenu()} className="hover:text-mfrust">
                                Übernachtung
                            </a>
                        </li>
                        <li>
                            <a href="#Feedback" onClick={() => closeMenu()} className="hover:text-mfrust">
                                Rückmeldung
                            </a>
                        </li>
                        <li>
                            <a href="#Contact" onClick={() => closeMenu()} className="hover:text-mfrust">
                                Kontakt
                            </a>
                        </li>
                        <li>
                            <a href="#Galerie" onClick={() => closeMenu()} className="hover:text-mfrust">
                                Galerie
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default MenuBar;
