import React from 'react';

import imgWasserburg from '../assets/wasserburg.jpg';
import imgRemise from '../assets/remise.jpg';

import anfahrt from '../assets/anfahrt.png';

const Location = () => {
    return (
        <div>
            <div id="Location" className="section bg-mfmedium/10">
                <h1 className="h1-primary">Location</h1>
                <div className="flex flex-col justify-center">
                    <div className="flex flex-wrap justify-center">
                        <div className="max-w-sm bg-white border border-gray-200 shadow-md m-5">
                            <a href="https://www.siegerlaenderburgenverein.de/index.php/ct-wasserburg-hainchen" target="_blank" rel="noreferrer">
                                <img className="h-48" src={imgWasserburg} alt="Wasserburg" />
                            </a>
                            <div className="flex space-x-2 text-sm justify-center m-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                </svg>

                                <p className="font-normal text-gray-700">Schloßstraße 20, 57250 Netphen</p>
                            </div>
                            <div className="p-5">
                                <h5 className="mb-2 text-2xl tracking-tight text-mfrust">Wasserburg Hainchen</h5>
                                <p className="mb-3 font-normal text-gray-700">Die Trauung findet im Freien auf dem Burgplatz der Wasserburg Hainchen statt. </p>
                            </div>
                        </div>

                        <div className="max-w-sm bg-white border border-gray-200 shadow-md m-5">
                            <a href="https://remise-hainchen.de/" target="_blank" rel="noreferrer">
                                <img className="h-48" src={imgRemise} alt="Wasserburg" />
                            </a>
                            <div className="flex space-x-2 text-sm justify-center m-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                </svg>

                                <p className="font-normal text-gray-700">Schloßstraße 19, 57250 Netphen</p>
                            </div>
                            <div className="p-5">
                                <h5 className="mb-2 text-2xl tracking-tight text-mfrust">Die Remise</h5>
                                <p className="mb-3 font-normal text-gray-700">Nach der freien Trauung geht es für die Feierlichkeiten in die direkt an der Wasserburg gelegene Remise.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Taxi" className="section">
                <h1 className="h1-primary">Taxi</h1>
                <div className="flex flex-col justify-center text-center">
                    <p className="text-center text-xl sm:text-xl pb-10 sm:px-[10%] px-5 text-stone-600">
                        Wir haben für die Nacht beim Taxiunternehmen Farnschläder Fahrten angemeldet. Solltet ihr ein Taxi benötigen, so wendet euch bitte an Taxi Farnschläder.
                    </p>
                    <p className="text-center text-xl sm:text-xl sm:px-[10%] px-5 text-stone-600 font-bold">Taxi Farnschläder</p>
                    <p className="text-center text-xl sm:text-xl pb-10 sm:px-[10%] px-5 text-stone-600">Tel.: +49 271 355400</p>
                </div>
            </div>

            <div id="Anfahrt" className="section bg-mfmedium/10">
                <h1 className="h1-primary">Anfahrt</h1>
                <div className="flex flex-wrap justify-center text-center">
                    <p className="text-center text-xl sm:text-xl pb-10 sm:px-[10%] px-5 text-stone-400 line-through">
                        Aufgrund einer Baustelle in Hainchen ist die Anfahrt zur Wasserburg über die Hauptstraße aktuell nicht möglich. Wir empfehlen eine Umgehung der Baustelle über den Meisenweg,
                        Kampenstraße, Am Sportplatz in den Dillweg und schließlich die Schloßstraße.
                    </p>
                    <p className="text-center text-xl sm:text-xl pb-10 sm:px-[10%] px-5 text-stone-600">
                        Die Bauarbeiten sind mittlerweile abgeschlossen und die Hauptstraße kann ganz normal befahren werden.
                    </p>

                    <div className="flex flex-col max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow ">
                        <img className="md:max-h-96 max-h-48 mb-4" src={anfahrt} alt="Anfahrt" />
                        <a
                            href="https://www.google.de/maps/dir/Netphen,+57250/Burg+Hainchen+-+Wasserburg,+Schloßstraße+20,+57250+Netphen/@50.8550897,8.2090508,16z/data=!4m14!4m13!1m5!1m1!1s0x47bc11ae63e53aab:0x6138fc3cc61883c5!2m2!1d8.207117!2d50.8572938!1m5!1m1!1s0x47bc1102f41cb7b1:0xb73a52b2da275fb6!2m2!1d8.2178115!2d50.8541221!3e0?entry=ttu"
                            target="_blank"
                            rel="noreferrer"
                            title="Anfahrt"
                            className="text-mfrust hover:text-black"
                        >
                            Google Maps
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Location;
