import React from 'react';
import { useCountdown } from '../hooks/useCountdown';

const Countdown = () => {
    const targetDate = new Date('2023-08-26T14:00:00.000Z');
    const [days, hours, minutes] = useCountdown(targetDate);

    return (
        <div id="Countdown" className="section bg-mfmedium/10">
            <h1 className="h1-primary">Wir heiraten</h1>
            <p className="text-center text-xl sm:text-2xl pb-10 sm:px-[10%] px-5 text-stone-600">... und das möchten wir mit unserer Familie und unseren engsten Freunden feiern.</p>
            <div className="flex justify-center">
                {/* <div className="flex flex-col justify-center items-center rounded-3xl shadow-md sm:m-4 m-2 sm:w-32 w-24 sm:h-32 h-24 bg-white"> */}
                <div className="flex flex-col justify-center items-center sm:m-4 m-2 sm:w-32 w-24 sm:h-32 h-24">
                    <p className="sm:text-7xl text-5xl text-mfrust">{days}</p>
                    <p className="sm:text-2xl text-md text-stone-600">{days === 1 ? 'day' : 'days'}</p>
                </div>
                <div className="flex flex-col justify-center items-center sm:m-4 m-2 sm:w-32 w-24 sm:h-32 h-24">
                    <p className="sm:text-7xl text-5xl text-mfrust">{hours}</p>
                    <p className="sm:text-2xl text-md text-stone-600">{hours === 1 ? 'hour' : 'hours'}</p>
                </div>
                <div className="flex flex-col justify-center items-center sm:m-4 m-2 sm:w-32 w-24 sm:h-32 h-24">
                    <p className="sm:text-7xl text-5xl text-mfrust">{minutes}</p>
                    <p className="sm:text-2xl text-md text-stone-600">{minutes === 1 ? 'minute' : 'minutes'}</p>
                </div>
            </div>
        </div>
    );
};

export default Countdown;
