import React from 'react';
import PropTypes from 'prop-types';

const Person = ({ id, firstname, lastname, handleInputChange, disabled }) => {
    Person.propTypes = {
        id: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        handleInputChange: PropTypes.func,
        disabled: PropTypes.bool,
    };

    return (
        <div className="flex space-x-2">
            <div className="flex flex-col">
                <input
                    type="text"
                    name={'firstname-' + id}
                    id={'firstname-' + id}
                    placeholder="Vorname"
                    className="w-full border-solid border-2 border-mfrust p-2 my-1 focus:border-stone-600 active:border-stone-600 peer disabled:border-mfrust/50"
                    required
                    value={firstname}
                    onChange={handleInputChange}
                    disabled={disabled}
                />
                <p className="invisible peer-invalid:visible text-mfrust font-light text-sm">Vorname benötigt.</p>
            </div>
            <div className="flex flex-col">
                <input
                    type="text"
                    name={'lastname-' + id}
                    id={'lastname-' + id}
                    placeholder="Nachname"
                    className="w-full border-solid border-2 border-mfrust p-2 my-1 focus:border-stone-600 active:border-stone-600 peer disabled:border-mfrust/50"
                    value={lastname}
                    required
                    onChange={handleInputChange}
                    disabled={disabled}
                />
                <p className="invisible peer-invalid:visible text-mfrust font-light text-sm">Nachname benötigt.</p>
            </div>
        </div>
    );
};

export default Person;
