import React, { useState, useEffect } from 'react';
import { CheckIcon, XIcon } from '@heroicons/react/outline';

const Guests = () => {
    const [guestList, setGuestList] = useState({});
    const [guestListRender, setGuestListRender] = useState('<></>');

    const getGuestList = async () => {
        let response = await fetch('http://18.196.104.49:9090/guests/get', {
            // let response = await fetch('http://localhost:9090/guests/get', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!response.ok) {
            throw new Error('Data could not be posted!');
        } else {
            var json = await response.json();
            return json;
        }
    };

    useEffect(() => {
        getGuestList().then((guests) => setGuestList(guests));
    }, []);

    useEffect(() => {
        setGuestListRender(
            <div className="section justify-center text-center">
                {guestList?.guests?.map((g) => {
                    return (
                        <div key={g._id} className="pb-5">
                            <div className="flex justify-center text-center items-bottom">
                                <p className="text-2xl">
                                    {g.guest.firstname} {g.guest.lastname}
                                </p>

                                {g.coming ? <CheckIcon className="w-8 h-8 text-mfgreen" /> : <XIcon className="w-8 h-8 text-mfrust" />}
                            </div>

                            {g.adults > 0 ? (
                                <div>
                                    <p className="text-mfrust font-medium pt-2">Erwachsene</p>
                                    {g.adultList.map((a) => {
                                        return (
                                            <p key={a._id}>
                                                {a.firstname} {a.lastname}
                                            </p>
                                        );
                                    })}
                                </div>
                            ) : null}

                            {g.kids > 0 ? (
                                <div>
                                    <p className="text-mfrust font-medium pt-2">Kinder</p>
                                    {g.kidList.map((k) => {
                                        return (
                                            <p key={k._id}>
                                                {k.firstname} {k.lastname}
                                            </p>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </div>
                    );
                })}
            </div>
        );
    }, [guestList]);

    return (
        <div className="section">
            <h1 className="h1-primary">Gästeliste</h1>
            <div>{guestListRender}</div>
        </div>
    );
};

export default Guests;
