import React from 'react';

const Hero = () => {
    return (
        <div id="Home" className="flex items-end h-screen bg-fixed bg-center bg-no-repeat bg-cover bg-[url('./assets/header.jpg')] selector">
            <div className="flex flex-col items-center bg-black/40 p-4 w-full lg:pt-36 md:pt-28 pt-16 mb-20">
                <h1 className="lg:text-6xl md:text-4xl xs:text-xl text-md text-white">Franzi &amp; Marian</h1>
                <p className="text-white pl-32 md:flex hidden lg:text-2xl md:text-xl">26.08.2023 | Wasserburg Hainchen</p>
                <p className="text-white pl-32 mt-[-5px] flex md:hidden text-sm">26.08.2023 | Wasserburg Hainchen</p>
            </div>
        </div>
    );
};

export default Hero;
